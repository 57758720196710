var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Data Entry',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" For cross browser consistency, "),_c('code',[_vm._v("<b-form-checkbox-group>")]),_vm._v(" and "),_c('code',[_vm._v("<b-form-checkbox>")]),_vm._v(" use Bootstrap's custom checkbox input to replace the browser default checkbox input. It is built on top of semantic and accessible markup, so it is a solid replacement for the default checkbox input. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('multiple')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('option-field-names')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('inline-and-stacked')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('sizing')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('checkbox-button')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('grouped-button')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('non-custom')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('validation')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('indeterminate')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }