<template>
  <b-card>
    <b-row class="justify-content-between">
      <b-col class="pr-md-32 pr-md-120">
        <h4 class="mb-0">Contextual state and validation example</h4>
      </b-col>

      <b-col class="hp-flex-none w-auto">
        <b-button
          @click="codeClick()"
          variant="text"
          class="btn-icon-only show-code-btn"
        >
          <i
            class="ri-code-s-slash-line hp-text-color-black-80 hp-text-color-dark-30 lh-1"
            style="font-size: 16px"
          ></i>
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <div class="col-12 mt-16">
        <b-form-checkbox-group
          v-model="value"
          :options="options"
          :state="state"
          name="checkbox-validation"
        >
          <b-form-invalid-feedback :state="state">
            Please select two
          </b-form-invalid-feedback>
          <b-form-valid-feedback :state="state">
            Thank you
          </b-form-valid-feedback>
        </b-form-checkbox-group>
      </div>

      <div
        v-if="codeActive"
        class="col-12 mt-24 hljs-container"
        :class="{ active: codeActiveClass }"
      >
        <pre v-highlightjs>
          <code class="hljs html">
            {{ codeText }}
          </code>
        </pre>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BFormCheckboxGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
} from "bootstrap-vue";

import code from "./code";

export default {
  data() {
    return {
      value: [],
      options: [
        { text: "First Check", value: "first" },
        { text: "Second Check", value: "second" },
        { text: "Third Check", value: "third" },
      ],
      codeText: code.validation,
      codeActive: false,
      codeActiveClass: false,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormCheckboxGroup,
    BFormInvalidFeedback,
    BFormValidFeedback,
  },
  computed: {
    state() {
      return this.value.length === 2;
    },
  },
  methods: {
    codeClick() {
      this.codeActive = !this.codeActive;

      setTimeout(() => {
        this.codeActiveClass = !this.codeActiveClass;
      }, 100);
    },
  },
};
</script>
